<script lang="ts" setup>
import { computed } from "vue";
import RegisterOverview from "@/components/energy/RegisterOverviewGrid.vue";

const breadcrumbs = computed(() => {
  return [{ default: "EU-Registers" }];
});
</script>

<template>
  <v-container fluid pa-0>
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <v-row>
        <v-col cols="auto">
          <header class="app-welcome-message">
            <h1>Overzicht van meters</h1>
          </header>
        </v-col>
        <v-col cols="auto">
          <v-btn dark class="primary" :to="{ name: 'EU-RegisterAdd', params: { userId: '-', addModus: true } }">
            <v-icon dark left>mdi-plus</v-icon>
            Meter toevoegen
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <header class="app-welcome-message">
            <p>In dit overzicht worden alle meters getoond.</p>
          </header>
        </v-col>
      </v-row>
      <v-row class="overview-wrapper">
        <v-col cols="12">
          <register-overview></register-overview>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
